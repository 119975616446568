.navigation {
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
	background: var(--white);

	&__item {
		color: var(--secondary);
		display: flex;
		line-height: 1;
		font-size: 1.7rem;
		font-weight: 300;

		@include screen(lg) {
			font-size: 1.5rem;
		}


		&:hover .navigation__dropdown {
			display: flex;
		}
	}

	&__link {
		color: inherit;
		padding: 0 1.5rem;
		height: 100%;
		display: flex;
		align-items: center;
		padding-top: 0;
		font-weight: 300;

		&:hover {
			color: var(--primary);
			text-decoration: none;
		}

		&.is-dropdown::after {
			content: '\f0d7';
			display: inline-block;
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			font-size: 0.8em;
			margin-left: 0.5em;
		}
	}

	&__dropdown {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		position: absolute;
		left: 0;
		top: 75px;
		display: none;
		background: var(--white);
		border-bottom: 0.1rem solid var(--light-grey);
		z-index: 2;
		border-top: 1px solid var(--light-grey);
	}
}
