.columns {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	&--border-bottom {
		border-bottom: 0.1rem solid var(--light-grey);
	}

	&__item {
		flex-grow: 1;
		flex-basis: 0;
		max-width: 100%;

		&--square {
			position: relative;

			&::after {
				content: '';
				width: 2rem;
				height: 2rem;
				background: var(--primary);
				display: block;
				position: absolute;
				left: calc(50% - 1rem);
				bottom: -1rem;
				z-index: 1;

				@include screen(sm){
					display: none;
				}
			}
		}

		&--lines {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			&::before {
				content: '';
				left: 50%;
				height: 100%;
				position: absolute;
				background: var(--light-grey);
				width: 0.1rem;
			}

			img {
				max-height: 22rem;
				z-index: 2;
				position: relative;
			}
		}
	}


}

.columns-section-headline {
	margin-bottom: 4rem;
	margin-left: 0;

	&::after{
		display: none;
	}

	&::before {
		display: none;
	}
}
