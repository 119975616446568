.footer-dark-cta {
	position: relative;
	color: var(--white);

	&__content {
		background: var(--secondary);
		padding: 6rem;
		margin-left: auto;
		width: 58rem;

		@include screen(md){
			padding: 3rem;
		    width: 45rem;
		}

		@include screen(sm) {
			width: 90%;
			margin: auto;
		}
	}

	&__form {
		margin-top: 5rem;

		@include screen(sm) {
			margin-top: 0;
		}
	}

	&__photo {
		position: absolute;
		bottom: -10rem;
		left: -5rem;
		pointer-events: none;

		@include screen(lg) {
			bottom: -13rem;
    		left: -21rem;
		}

		@include screen(sm) {
			position: relative;
			bottom: inherit;
			left: inherit;
			max-width: 90%;
			margin: 0 auto;
			margin-top: 4rem;
		}

		& img {
			max-width: 60%;

			@include screen(sm) {
				max-width: 100%;
			}
		}
	}
}
