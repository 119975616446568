.header {
	display: flex;
	color: var(--secondary);
	background: var(--white);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
	height: 76px;
	left: 0;
	transition: all ease 0.25s;

	@include screen(md) {

	}

	&::after {
		width: 100%;
		height: 1px;
		background: var(--light-grey);
		position: absolute;
		bottom: 0;
		left: 0;
		content: '';
	}

	&__logo {
		padding: 2.5rem;
		width: 170px;
		display: block;
	}

	&__btn {
		padding: 2.5rem 3.0rem;
		color: inherit;
		border-left: 1px solid var(--light-grey);

		@include screen(lg){
			padding: 2.5rem 2rem;
		}

		&:hover {
			color: var(--primary);
			text-decoration: none;
		}

		&--sign-in {
			margin-left: auto;
			font-weight: 300;

			&::before {
				content: '';
				background-image: url('../img/icons/icon--user.png');
				display: inline-block;
				width: 2.1rem;
				height: 2.1rem;
				background-size: contain;
				vertical-align: middle;
				margin-right: 1rem;
			}
		}

		&--request-demo {
			font-weight: 600;
		}
	}

	&__hamburger {
		display: none;
		cursor: pointer;
		font-size: 3rem;
		color: var(--secondary);
		align-items: center;
		justify-content: center;
		width: 75px;
		text-align: center;

		i {
			vertical-align: middle;
		}
	}
}

// mobile
//

@include screen(md) {

	.header__navigation{
		display: none;
	}

	.header__btn--sign-in {
		display: none;
	}

	.header__btn--request-demo {
		margin-left: auto;
	}

	.header__logo {
		padding-left: 1rem;
	}

	.header__hamburger {
		display: flex;
	}
}
