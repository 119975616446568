.headline {
	margin: 0;
	padding: 0;
	position: relative;
	margin-bottom: 1.6rem;

	span {
		color: var(--primary);
	}

	&--h2 {
		font-size: 3.3rem;
    	font-weight: 300;

		@include screen(lg) {
			font-size: 3rem;
		}

		@include screen(md) {
			font-size: 2.4rem;
		}
	}

	&--h3 {
		font-size: 2rem;
		font-weight: 300;

		@include screen(lg) {
			font-size: 1.8rem;
		}
	}

	&--underline {
		padding-bottom: 1.6rem;

		&::after {
			content: '';
			width: 8rem;
			background: var(--primary);
			height: 0.1rem;
			left: 0;
			bottom: 0;
			position: absolute;
		}
	}

	&--underline-center {
		margin-bottom: 0.8em;
		padding-bottom: 0.8em;

		&::after {
			content: '';
			width: 25rem;
			background: var(--primary);
			height: 0.1rem;
			left: calc(50% - 12rem);
			bottom: 0;
			position: absolute;
		}
	}

	&--quote {
		margin: 0;
		padding: 0;
		font-weight: 700;
		font-size: 3.3rem;
		color: var(--primary);

		@include screen(md) {
			font-size: 2.4rem;
		}

		@include screen(sm) {
			font-size: 1.8rem;
		}

		&::before {
			display: inline-block;
			content: '';
			background-image: url('../img/icons/icon--quote.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			width: 3.6rem;
			margin-right: 2rem;

			@include screen(sm) {
				width: 2.2rem;
			}
		}
	}

	&--square {
		display: inline-block;
		margin-left: 6rem;

		@include screen(sm) {

		}

		&::before {
			content: '';
			width: 2rem;
			height: 2rem;
			background: var(--primary);
			display: block;
			position: absolute;
			left: -6.75rem;
			bottom: calc(50% - 1rem);
			z-index: 1;

			@include screen(sm) {
				width: 1.5rem;
				height: 1.5rem;
				left: -5.25rem;
				bottom: calc(50% - 0.75rem);
			}
		}

		&::after {
		    width: 7.5rem;
		    content: '';
		    position: absolute;
		    left: -9.5rem;
		    height: .1rem;
		    background: var(--light-grey);
		    top: calc(50% - .1rem);

			@include screen(sm) {
				width: 5rem;
				left: -7rem;
			}
		}
	}

	&--square-side {
		display: inline-block;
		margin-left: 4rem;

		&::before {
			content: '';
			width: 2rem;
			height: 2rem;
			background: var(--primary);
			display: block;
			position: absolute;
			z-index: 1;
			left: -5rem;
			top: calc(50% - 1rem);

			@include screen(sm) {
				width: 1.5rem;
				height: 1.5rem;
				top: calc(50% - 0.75rem);
				left: -4.75rem;
			}

		}

		&::after {
		    width: 0.1rem;
		    content: '';
		    position: absolute;
		    height: 100%;
			left: -4rem;
		    background: var(--light-grey);
			top: 0;
		}
	}
}
