.icon-blocks {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.icon-block {
		flex: 0 0 40%;
		margin-bottom: 5rem;

		@include screen(lg) {
			flex: 0 0 45%;
		}

		@include screen(sm) {
			flex: 0 0 100%;
		}
	}
}
