.dropdown-card {
	background: var(--secondary);
	background-size: cover;
	background-position: center center;
	padding: 5rem;
	color: var(--white);
	position: relative;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;

	@include screen(lg) {
		flex: 0 0 25%;
		padding: 2.5rem;
	}

	&::before {
		content: '';
		background: var(--black);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		opacity: 0.65;
		display: block;
	}

	&__inner {
		display: flex;
		flex-direction: column;
		height: 27rem;
		position: relative;
		z-index: 2;

		@include screen(lg) {

		}
	}

	&__title {
		text-transform: uppercase;
		font-size: 1.7rem;
		font-weight: 600;
		margin: 0;
		padding: 0;
		margin-bottom: 0.8em;
		padding-bottom: 0.8em;
		position: relative;

		@include screen(lg) {
			font-size: 1.5rem;
		}

		&::after {
			content: '';
			width: 7.5rem;
			background: var(--white);
			height: 0.1rem;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}

	&__text {
		margin: 0;
		padding: 0;
		line-height: 1.4;
		margin-bottom: 2em;
		align-self: flex-start;

		@include screen(lg) {
			font-size: 1.4rem;
		}

		&--link {
			font-weight: 600;
			color: inherit;

			&:hover {
				text-decoration: none;
				color: var(--primary);
			}
		}
	}

	&__btn {
		margin-top: auto;
		align-self: flex-start;
	}

	&--inverse{
		color: var(--secondary);
		background: var(--white);

		&:before {
			display: none;
		}

		.dropdown-card__title::after {
			background: var(--primary);
		}
	}
}
