.pricing-table {

	&__top,
	&__middle {
		display: flex;
		background: #fff;
	}

	&__corner,
	&__header,
	&__column {
		flex: 0 0 24%;
	}

	&__item {
		background: var(--white);
		min-height: 70px;
		padding: 1.5rem 2rem;
		border-bottom: 1px solid #efefef;

		@include screen(md) {
			min-height: 74px;
		}

		&--grey {
			background: #f7f7f7;
			border-bottom: 1px solid #d0d0d0;
		}

		&--grey-2 {
			background: #ededed;
			border-bottom: 1px solid #cecece;
		}

		&--grey-3 {
			background: #e5e5e5;
			border-bottom: 1px solid #cecece;
		}

		& p {
			font-weight: 700;
			color: var(--secondary);
			font-size: 1.6rem;
			line-height: 1.2;
			margin: 0;

			@include screen(md) {
				font-size: 1.2rem;
			}

		}

		& .fa {
			border: 4px solid var(--primary);
			display: inline-block;
			font-size: 1.8rem;
			height: 1.9em;
			width: 1.9em;
			line-height: 1.5em;
			border-radius: 100px;
			color: var(--primary);

			&-times {
				color: #cccccc;
				border: 4px solid #cccccc;
			}
		}

		&--option {
			text-align: center;
			box-shadow: -4px 0px 15px -10px rgba(20, 20, 20, 0.05),
						4px 0px 15px -10px rgba(20, 20, 20, 0.05);
			position: relative;
			z-index: 2;

			& p {
				margin-bottom: 1rem;
				font-size: 1.4rem;
				display: none;

				@include screen(sm) {
					display: inline-block;
				}
			}
		}
	}



	&__gap {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;

		&-item {
			display: block;
			width: 100%;
			height: 70px;

			@include screen(md) {
				height: 74px;
			}

			&--grey {
				background: #f7f7f7;
			}

			&--grey-2 {
				background: #ededed;
			}

			&--grey-3 {
				background: #e5e5e5;
			}
		}
	}

	&__header {
		text-align: center;
		padding: 4rem;
		background: var(--white);
		border-bottom: 1px solid #efefef;
		box-shadow: 0px 4px 22px 0px rgba(20, 20, 20, 0.05);

		&--mobile {
			display: none;

			@include screen(sm) {
				display: block;
			}
		}

		@include screen(lg) {
			padding: 4rem 2rem;
		}

		@include screen(sm) {
			box-shadow: none;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 2rem;
		line-height: 1.2;
		padding-bottom: 2rem;
		min-height: 52px;

		@include screen(md) {
			font-size: 1.4rem;
			min-height: 40px;
		}

		@include screen(sm) {
			font-size: 2rem;
			min-height: 52px;
		}
	}

	&__btn {

		@include screen(md) {
			font-size: 1.3rem;
		}

		@include screen(sm) {
			font-size: 1.6rem;
			width: 100%;
		}
	}

	&__price {
		color: var(--primary);
		font-size: 3.7rem;
		font-weight: 700;
		line-height: 1;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 100%;
		flex-wrap: wrap;
		margin-bottom: 2rem;

		@include screen(md) {
			font-size: 2.6rem;
		}

		@include screen(sm) {
			font-size: 3.8rem;
		}

		&-sub {
			font-weight: 400;
			font-size: 1.7rem;
			color: var(--secondary);
			line-height: 1;
			flex: 0 0 100%;

			@include screen(md) {
				font-size: 1.2rem;
			}
		}
	}

	&__property {
		margin-bottom: 2rem;
	}


	&.is-scrolled {

		padding-top: 296px;

		@include screen(md) {
			padding-top: 255px;
		}

		@include screen(sm) {
			padding: 0;
		}

		.pricing-table__top {
			position: fixed;
		    top: var(--pricing-table-top);
		    width: 1110px;
		    z-index: 3;
		    background: #fff;

			@include screen(lg) {
				width: 930px;
			}

			@include screen(md) {
				width: 690px;
			}
		}


	}

	&.is-fixed {
		padding-top: 296px;

		@include screen(md) {
			padding-top: 255px;
		}

		@include screen(sm) {
			padding: 0;
		}

		.pricing-table__top {
			position: absolute;
			top: var(--pricing-table-top);
			 width: 1110px;
 		    z-index: 3;
 		    background: #fff;

			@include screen(lg) {
				width: 930px;
			}

			@include screen(md) {
				width: 690px;
			}
		}
	}
}

@include screen(sm) {

	.pricing-table__top {
		display: none;
	}

	.pricing-table__middle {
		display: flex;
		flex-wrap: wrap;
	}

	.pricing-table__column--first {
		display: none;
	}

	.pricing-table__corner {
		display: none;
	}

	.pricing-table__gap {
		display: none;
	}

	.pricing-table__header {
		flex: 0 0 100%;
	}

	.pricing-table__column {
		flex: 0 0 100%;
		margin-bottom: 4rem;
		box-shadow: 0px 4px 22px 0px rgba(20, 20, 20, 0.05);
	}

	.pricing-table__item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		p {
			flex: 0 0 100%;
			margin-bottom: 1rem;
		}
	}
}
