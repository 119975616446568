.dropdown-demo {
	background: var(--primary);
	color: var(--white);
	font-size: 1.7rem;
	display: flex;
	align-items: center;
	background-position: center center;
	background-size: cover;
	flex: 0 0 50%;

	&__inner {
		height: 12.5rem;
		display: flex;
		background: rgba(0,0,0,0.75);
		margin-left: 5rem;
	}

	&__text {
		margin: 0;
		padding: 0 5rem;
		max-width: 22rem;
		display: flex;
		align-items: center;
	}

	&__btn {
		color: inherit;
		padding: 0 4rem;
		display: flex;
		align-items: center;
		position: relative;
		font-weight: 600;
		transition: all ease 0.25s;
		padding-bottom: 3rem;

		&::after {
			content: '\f061';
			display: block;
			position: absolute;
	        font-style: normal;
	        font-variant: normal;
	        text-rendering: auto;
	        -webkit-font-smoothing: antialiased;
	        font-family: "Font Awesome 5 Free";
	        font-weight: 900;
	        line-height: 1;
			text-align: center;
			font-size: 2rem;
			width: 100%;
			left: 0;
			bottom: 30px;
		}

		&:hover {
			color: var(--white);
			text-decoration: none;
			background: rgba(#54a355, 0.8);
		}
	}
}
