.data-points {
	background: var(--white);
	box-shadow: 0px 4px 22px 0px rgba(20, 20, 20, 0.05);
	position: relative;

	&__inner {
		padding: 8rem;
		background: var(--white);
		position: relative;
		z-index: 2;
	}

	&::after {
		background: var(--white);
		content: '';
		display: block;
		width: 10rem;
		height: 10rem;
		position: absolute;
		bottom: -5rem;
		left: calc(50% - 5rem);
		transform: rotate(45deg);
		box-shadow: 0px 4px 22px 0px rgba(20, 20, 20, 0.05);
		z-index: 1;
		pointer-events: none;
	}

	&__items {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 10rem;
	}

	&__title {
		margin-bottom: 0.4em;
	}

	&__item {
		flex-grow: 1;
		flex-basis: 0;
		position: relative;
		padding-left: 4rem;
		flex: 0 0 30%;

		@include screen(sm) {
			flex: 0 0 48%;
		}

		@include screen(xs) {
			flex: 0 0 100%;
		}

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0.2rem;
			left: 0;
			width: 1.8rem;
			height: 1.8rem;
			background-image: url('../img/icons/icon--checkmark.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
		}
	}

	&__header {
		font-weight: 600;
		text-align: center;
		padding-bottom: 3rem;
	}

	&--text-only {

		.data-points__items {
			margin-bottom: 0;
		}

		.data-points__inner {
    		padding: 3rem;
		}

		.data-points__item {
			padding-left: 3rem;
		}

		.data-points__text {
			margin-bottom: 0.5em;
		}

		.data-points__item::before {
			top: 0.4rem;
		}

		&::after {
		    width: 5rem;
		    height: 5rem;
		    bottom: -2rem;
		    left: calc(50% - 2.5rem);
		}
	}
}
