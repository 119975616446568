p {
	margin: 0;
	padding: 0;
	margin-bottom: 1.5rem;
	line-height: 1.5;
}

strong {
	font-weight: 600;
}

h1,h2,h3,h4,h5,h6 {
	margin: 0;
	padding: 0;
}

h2 {
	font-size: 3.3rem;
	font-weight: 300;
}

h3 {
	font-size: 1.7rem;
	font-weight: 600;
}
