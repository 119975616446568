.fancybox-navigation .fancybox-button {
    width: 150px;
    height: 150px;
    color: #fff;
}

.fancybox-navigation .fancybox-button div {
    background: var(--primary);
}

.fancybox-navigation .fancybox-button[disabled] {
    opacity: 0;
}
