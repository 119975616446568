.content {

	h1,h2,h3,h4,h5,h6 {
		margin-bottom: 1.5rem;
		margin-top: 2.5rem;
	}

	p {
		font-size: 2rem;
		margin-bottom: 1.5em;

		@include screen(md) {
			font-size: 1.6rem;
		}
	}

	h3 {
		font-size: 2.1rem;

		@include screen(md) {
			font-size: 1.8rem;
		}
	}
}
