.btn {
	border: 0.2rem solid var(--primary);
	font-size: 1.7rem;
	font-weight: 600;
	border-radius: 0.3rem;
	padding: 0.7em 2.6em;
	line-height: 1;

	@include screen(md) {
		font-size: 1.5rem;
	}

	&:hover {
		text-decoration: none;
		background: var(--primary);
		color: var(--white);
	}

	&:focus {
		box-shadow: none;
	}

	&--inverted {
		background: none;
		color: var(--white);
		border: 0.2rem solid var(--white);

		&:hover {
			background: var(--white);
			color: var(--secondary);
		}
	}

	&--secondary {
		background: none;
		color: var(--secondary);
		border: 0.2rem solid var(--secondary);

		&:hover {
			background: var(--secondary);
			color: var(--white);
		}
	}

	&--primary-solid {
		background: var(--primary);
		color: var(--white);

		&:hover {
			background: var(--primary-darker);
			border-color:  var(--primary-darker);
		}
	}

	&--sm {
		padding: 0.7em 1.2em;
	}

	&--fixed-width {
		width: 19rem;
		padding-left: 1em;
		padding-right: 1em;
	}

	&--full-width {
		width: 100%;
	}
}
