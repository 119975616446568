.steps {

	&__item {
		display: flex;
		align-items: center;
		position: relative;
		margin-bottom: 5rem;
		flex-wrap: wrap;

		&.is-active .steps__number {
			color: var(--primary);
		}

		&.is-active .steps__text strong {
			color: var(--primary);
		}

		&.is-active .steps__divider {
			background: var(--primary);
		}

		@include screen(sm) {
			margin-bottom: 3rem;
		}
	}

	&__number {
		font-size: 5rem;
		font-weight: 600;
		padding-right: 1em;
		flex: 0 0 11rem;
		text-align: center;
		align-self: flex-start;
		line-height: 1;
		color: var(--light-grey);

		@include screen(lg){
			    padding-right: 0.8em;
    			flex: 0 0 7rem;
		}

		@include screen(md){
			font-size: 4rem;
		}
	}

	&__text {
		padding: 0;
		margin: 0;
		flex: 0 0 28rem;
		line-height: 1.4;

		@include screen(lg) {
			flex: 0 0 30rem;
		}

		@include screen(sm) {
			flex: 1;
			padding-right: 2rem;
		}
	}

	&__divider {
		height: 2px;
		background: var(--light-grey);
		flex-grow: 1;
		flex-basis: 0;
		margin: 0 45rem 0 8rem;

		@include screen(lg) {
			margin: 0 41rem 0 6rem;
		}

		@include screen(md) {
			display: none;
		}
	}
}
