.subscribe {
	background: var(--secondary);
	color: var(--white);
	padding: 4.8rem 0;

	&__inner {
		display: flex;

		@include screen(sm) {
			flex-wrap: wrap;
			padding: 0 2rem;
		}
	}

	&__content {
		padding-right: 4rem;

		@include screen(sm) {
			padding: 0;
			padding-bottom: 4rem;
		}
	}

	&__form {
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 60%;

		@include screen(lg) {
			flex-basis: 50%;
		}

		@include screen(sm) {
			flex: 0 0 100%;
		}
	}

	&__title {
		font-weight: 300;
		text-transform: uppercase;
		font-size: 2.0rem;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
		position: relative;

		&::after {
			content: '';
			width: 7.5rem;
			background: var(--primary);
			height: 1px;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}

	&__input-group {
		display: flex;
	}


	&__input {
		background: none;
		border: 1px solid var(--white);
		font-size: 1.7rem;
		border-radius: 2px 0 0 2px;
		padding: 0;
		padding-left: 1.2em;
		line-height: 4.6rem;
		border-right: none;
		// flex-grow: 1;
		transition: border ease 0.25s;
		color: var(--white);
		font-weight: 300;
		width: 100%;

		&::placeholder{
			color: var(--ccc);
			font-weight: 300;
		}

		&:focus {
			border: 1px solid var(--primary);
		}
	}

	&__submit {
		border-radius: 0 2px 2px 0;
	}

	&__small-text {
		margin: 0;
		margin-top: 1.5em;
		font-size: 1.3rem;

		& a {
			color: inherit;
			text-decoration: underline;
		}
	}

	&__text {
		margin-bottom: 0;
	}
}
