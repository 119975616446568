.footer {

	border-top: 0.1rem solid var(--light-grey);
	background: var(--white);


	&__items {
		display: flex;
		padding: 5rem 2rem;

		@include screen(lg) {
			padding: 5rem 0;
		}

		@include screen(sm) {
			flex-wrap: wrap;
			padding: 5rem 2rem;
			margin: 0 auto;
			max-width: 390px;
			width: 90%;
		}
	}

	&__item {
		flex: 1;
		flex-basis: 0;
		width: 100%;

		&--first {
			flex-grow: 0;
			padding: 0 3rem;
			padding-right: 10rem;

			@include screen(lg) {
				padding-right: 2rem;
			}

			@include screen(sm) {
				flex: 0 0 100%;
				padding: 0 1rem;
			}
		}

		&--flex {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			@include screen(md) {
				justify-content: flex-start;
				padding-left: 4rem;
			}

			@include screen(sm) {
				padding: 0;
			}

			& .widget {
				flex: 1;
				flex-basis: 0;
				width: 100%;
				padding: 0 2rem;

				@include screen(lg) {
					padding: 0 1rem;
				}

				@include screen(md) {
					flex: 0 0 33%;
				}

				@include screen(sm) {
					flex: 0 0 50%;
				}

			}
		}
	}

	.social-media {
		@include screen(sm) {
			justify-content: center;
		}
	}
}
