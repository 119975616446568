.testimonial-slider {
	padding: 7rem 0;

	@include screen(md) {
		padding: 4rem 0;
	}

	&__inner {
		background: var(--white);
		padding: 7.5rem 12rem;
		box-shadow: 0px 4px 22px 0px rgba(20, 20, 20, 0.05);

		@include screen(lg) {
			padding: 7rem;
		}

		@include screen(md) {
			padding: 5rem;
		}

		@include screen(sm) {
			padding: 3rem;
		}
	}

	&__header {
		text-align: center;
		padding-bottom: 7rem;

		& h2 {
			position: relative;
			display: inline-block;

			&::after {
				width: 7.5rem;
				content: '';
				position: absolute;
				left: -9.5rem;
				height: 0.1rem;
				background: var(--light-grey);
				top: calc(50% - 0.1rem);
			}

			&::before {
				content: '';
				width: 2rem;
				height: 2rem;
				background: var(--primary);
				display: block;
				position: absolute;
				left: -6.75rem;
				bottom: calc(50% - 1rem);
				z-index: 1;
			}
		}
	}

	&__item {
		position: relative;
		padding-left: 12rem;
		padding-right: 5rem;

		@include screen(md) {
			padding-left: 7rem;
		}

		@include screen(sm) {
			padding-right: 2rem;
		}

		&::before {
			content: '';
			background-image: url('../img/icons/icon--quote.png');
			background-repeat: no-repeat;
			background-position: center top;
			background-size: contain;
			position: absolute;
			left: 0;
			top: 0;
			width: 7.5rem;
			height: 10rem;

			@include screen(md) {
				width: 4rem;
			}
		}
	}

	&__quote {
		font-size: 2rem;
		margin-bottom: 3rem;

		@include screen(md) {
			font-size: 1.8rem;
		}

		@include screen(sm) {
			font-size: 1.6rem;
			line-height: 1.3;
		}
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@include screen(sm) {
			flex-wrap: wrap;
		}
	}

	&__author {

		@include screen(sm) {
			flex: 0 0 100%;
		}

		& p {
			margin-bottom: 0.2em;
		}
		& p:last-child {
			margin: 0;
		}
	}

	&__logo {
		max-width: 16.5rem;

		@include screen(md) {
			max-width: 10rem;
		}

		@include screen(sm){
			margin-top: 2em;
			margin-left: auto;
		}

		img {
    		max-height: 8rem;
		}

		&--circle img {
			max-height: 10rem;

			@include screen(md) {
				max-height: 8rem;
			}
		}

		&--sm img {
			max-height: 7rem;
		}
	}
}
