.widget {
	margin: 0;
	padding: 0;
	margin-bottom: 4.5rem;

	&__content {
		@include screen(sm) {
			text-align: center;
		}
	}

	&__title {
		margin: 0;
		padding: 0;
		font-size: 1.7rem;
		color: var(--secondary);
		font-weight: 600;
		margin-bottom: 1.1em;
		padding-bottom: 1.1em;
		position: relative;

		@include screen(sm) {
			text-align: center;
		}

		&::after {
			content: '';
			width: 7.5rem;
			background: var(--primary);
			height: 0.1rem;
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;

			@include screen(sm) {
				left: calc(50% - 3.75rem)
			}
		}

		&--no-border {
			padding-bottom: 0;

			&::after {
				display: none;
			}
		}
	}

	&__logo {
		max-width: 17rem;
		display: inline-block;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		font-size: 1.7rem;
		display: flex;
		flex-direction: column;

		&-item {
			margin: 0;
			padding: 0;
			margin-bottom: 1em;
			line-height: 1.2;

			@include screen(sm) {
				padding-left: 1.6rem;
			}
		}

		&-link {
			color: var(--secondary);
			font-weight: 300;

			&:hover {
				color: var(--primary);
				text-decoration: none;
			}

			@include hover-arrow;
		}
	}
}
