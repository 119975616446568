.copyright {
	background: var(--secondary);
	color: var(--white);
	display: flex;
	justify-content: space-between;
	align-items: center;
	line-height: 1;

	&__text {
		margin: 0;
		padding: 0;
		padding: 0 1.5rem;
	}

	&__link {
		color: inherit;
		font-weight: 600;
		padding: 1.5rem 2.5rem;
		display: inline-block;

		&:hover {
			color: var(--primary);
			text-decoration: none;
		}

		&:first-child {
			border-left: 0.1rem solid var(--light-grey);
		}
	}
}
