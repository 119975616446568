.social-media {
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 1.8rem;

	&--inverse {
		.social-media__link {
			background: var(--white);
			color: var(--secondary);

			&:hover {
				text-decoration: none;
				background: var(--primary);
				color: var(--white);
			}
		}
	}

	&__item {
		color: var(--white);
		margin-right: 1rem;
	}

	&__link {
		color: inherit;
		background: var(--secondary);
		width: 1.8em;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 1.8em;
		vertical-align: middle;
		border-radius: 10rem;

		&:hover {
			text-decoration: none;
			background: var(--primary);
			color: var(--white);
		}
	}


}
