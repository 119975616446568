.form {

	&__items {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -4rem;
		align-items: flex-start;

		&:last-child .form__item {
			margin-bottom: 0;
		}

		@include screen(sm) {
			margin: 0 auto;
		}
	}

	&__item {
		flex-basis: 0;
		flex-grow: 1;
		width: 100%;
		position: relative;
		max-width: 100%;
		display: flex;
		flex-wrap: wrap;
		padding: 0 4rem;
		margin-bottom: 2.5rem;
		align-items: flex-start;

		@include screen(sm) {
			flex: 0 0 100%;
			padding: 0;
		}

		&--right {
			justify-content: flex-end;
		}
	}

	&__label {
		margin: 0;
		padding: 0;
		font-weight: 600;
		margin-bottom: 0.3em;
		font-size: 1.7rem;
		flex: 0 0 100%;
	}

	&__input {
		border: 1px solid var(--ccc);
		height: 4.5rem;
		line-height: 4.5rem;
		padding-left: 1.5rem;
		font-size: 1.7rem;
		border-radius: 3px;
		flex: 0 0 100%;
		width: 100%;
		appearance: none;
		position: relative;

		&:focus {
			border: 1px solid var(--secondary);
			color: var(--secondary);

			&::placeholder {
				color: var(--ccc);
			}
		}

		&::placeholder {
			color: var(--ccc);
		}
	}

	&__checkboxes {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 0 0 100%;

		@include screen(sm) {
			justify-content: flex-start;
		}
	}

	&__checkbox {

		span.wpcf7-list-item {
			display: block;
			margin: 0;
			padding: 0;

			@include screen(sm) {
				margin-right: 2rem;
			}
		}

		input {
			display: none;

			& + span {
				cursor: pointer;
				position: relative;
				margin: 0;
				vertical-align: middle;
			}

			& + span:before {
				content: '';
				display: inline-block;
				width: 2rem;
				height: 2rem;
				border: 1px solid var(--ccc);
				background: var(--white);
				border-radius: 3px;
				margin-right: 10px;
				transition: all ease 0.25s;
				vertical-align: middle;
				position: relative;
				top: -2px;
			}

			&:hover + span:before {
			   border: 1px solid var(--primary);
			}

			&:checked + span:before {
				border: 1px solid var(--primary);
				background: var(--primary);
			}

			&:checked + span:after {
				content: '\f00c';
				position: absolute;
				display: inline-block;
			    font-style: normal;
			    font-variant: normal;
			    text-rendering: auto;
			    -webkit-font-smoothing: antialiased;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				color: var(--white);
			    left: 4px;
			    top: 4px;
			    font-size: 1.2rem;



				@include screen(md) {
					top: 2px;
				}

				@include screen(sm) {
					top: 1px;
				}
			}
		}
	}

	&__textarea {
		width: 100%;
		border: 1px solid var(--ccc);
		border-radius: 3px;
		padding: 1rem 1.5rem;

		&::placeholder {
			color: var(--ccc);
		}
	}
}
