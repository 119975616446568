.section {
	padding: 11rem 0;
	background: var(--white);

	@include screen(lg) {
		padding: 9rem 0;
	}

	@include screen(md) {
		padding: 6rem 0;
	}

	@include screen(sm) {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	&--lightest-grey {
		background: var(--lightest-grey);
	}

	&__title {
		&--center {
			text-align: center;
		}
	}
}
