.icon-block {
	display: flex;
	flex-grow: 1;
	flex-basis: 0;

	&__icon {
		flex: 0 0 11rem;
		padding-right: 3.5rem;
		text-align: center;

		img {
			max-height: 6.5rem;
		}
	}

	&__title {
		font-size: 1.7rem;
		font-weight: 600;
		margin: 0;
		padding: 0;
		margin-bottom: 0.5em;
	}
}
