:root {
	--green: #54a355;
	--grey: #434343;
	--light-grey: #6f6f70;
	--lightest-grey: #f7f7f7;
	--ccc: #cccccc;
	--white: #fff;
	--black: #000;

	--primary: var(--green);
	--secondary: var(--grey);


	--primary-30: #cae2cc;
	--primary-darker: #428043;

	--border-radius: 4px;

	--pricing-table-top: 76px;
}
