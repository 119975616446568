.wpcf7-not-valid-tip {
    color:  var(--primary);
    font-size: 0.5em;
    font-weight: normal;
    display: block;
    position: absolute;
    bottom: 2px;
    right: 4px;
    font-weight: 600;
}

.wpcf7-form-control-wrap {
	background: none;
	padding: 0;
	margin: 0;
	flex-grow: 1;
	display: flex;
}

div.wpcf7 .ajax-loader {
	position: absolute;
	right: -10px;
	display: block;

}

.wpcf7-not-valid {
    border: 2px solid var(--primary) !important;
    border-color: var(--primary) !important;

    &::placeholder {
        color: var(--primary) !important;
    }
}

.wpcf7 form .wpcf7-response-output {
    font-size: 1.2rem;
}

.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
    border-color: red;
}


.wpcf7-form-control-wrap.product,
.wpcf7-form-control-wrap.market,
.wpcf7-form-control-wrap.report-type {
    position: relative;
    cursor: pointer;

    &::after {
        content: '\f107';
        width: 20px;
        height: 20px;
        font-size: 20px;
        line-height: 1;
        right: 5px;
        top: calc(50% - 8px);
        position: absolute;
        pointer-events: none;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Free"; font-weight: 900;
        color: var(--secondary);
    }
}


.wpcf7-select {
    cursor: pointer;
}
