.timeline {
	display: flex;
	border-top: 1px solid var(--light-grey);

	@include screen(sm) {
		flex-wrap: wrap;
		border-top: none;
	}

	&__item {
		text-align: center;
		flex-grow: 1;
		flex-basis: 0;
		max-width: 100%;
		position: relative;
		padding-top: 3rem;
		display: flex;


		@include screen(sm) {
			flex: 0 0 100%;
			margin-bottom: 7rem;

			&:first-child::before {
				width: 1px;
				content: '';
				height: 8rem;
				position: absolute;
				left: calc(50% - 1px);
				background: var(--secondary);
				bottom: 90%;
				top: inherit;
			}

			&::after {
				width: 1px;
				content: '';
				height: 8rem;
				position: absolute;
				left: calc(50% - 1px);
				background: var(--secondary);
				top: 100%;
			}
		}

		&::before {
			content: '';
			width: 2rem;
			height: 2rem;
			background: var(--primary);
			display: block;
			position: absolute;
			left: calc(50% - 1rem);
			top: -1rem;

			@include screen(sm) {
				top: -4rem;
			}
		}
	}

	&__inner {
		width: 45%;
		margin: 0 auto;

		@include screen(lg) {
			width: 70%;
		}

		@include screen(sm) {
			width: 100%;
		}
	}

	&__title {
		margin: 0;
		padding: 0;
		margin-bottom: 0.7em;
	}

	&__text {
		line-height: 1.2;
	}
}
