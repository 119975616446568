.quote-block {
	padding-left: 8rem;
	position: relative;
	line-height: 1.3;

	@include screen(md) {
		padding-left: 4rem;
	}

	&::before {
		content: '';
		background-image: url('../img/icons/icon--quote.png');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: contain;
		position: absolute;
		left: 0;
		top: 0;
		width: 6.5rem;
		height: 10rem;

		@include screen(md) {
			width: 2.4rem;
		}
	}

	&__quote {
		margin-bottom: 2rem;
	}

	&__position {
		font-weight: 600;
	}
}
