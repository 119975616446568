.slick-dots {
	bottom: 0;

	li {
		width: auto;
		height: auto;
		text-align: center;
		margin: 0 1.4rem;

		button {
			width: 1.5rem;
			height: 1.5rem;
			background: var(--white);
			border: 0.1rem solid var(--light-grey);
			border-radius: 10rem;
			transition: all ease 0.25s;

			&::before {
				display: none;
			}

			&:hover {
				background: var(--light-grey)
			}

		}

		&.slick-active button {
			background: var(--primary);
			border: 0.1rem solid var(--primary);
		}

	}
}

.slick-dotted.slick-slider {
    margin-bottom: 0;
	padding-bottom: 6rem;
}
