.toggle {
	display: flex;
	flex-wrap: nowrap;
	margin: 0 auto;
	width: 40rem;
	height: 6rem;
	background: #f0f0f0;
	border-radius: 100px;
	padding: 6px;
	box-shadow: inset 0px 3px 15px -9px rgba(0,0,0,0.5);
	font-size: 2rem;
	position: relative;
	cursor: pointer;

	&::before {
		content: '';
		width: 49%;
		position: absolute;
		height: 5.4rem;
		top: 3px;
		display: block;
		background: red;
		border-radius: 100px;
		background: var(--primary);
		box-shadow: inset 1px -9px 15px -9px rgba(0,0,0,0.5);
		left: 4px;
		transform: translateX(0);
		transition: all ease 0.25s;
	}

	&.is-toggled {
		&::before {
			transform: translateX(100%)
		}
	}

	&__item {
		flex-grow: 1;
		flex-basis: 0;
		max-width: 100%;
		display: flex;
		justify-content: center;
		font-weight: 600;
		background: none;
		cursor: pointer;
		box-shadow: inset 1px -9px 15px -9px rgba(0,0,0,0.0), 0px 4px 22px 0px rgba(20, 20, 20, 0.0);
		align-items: center;
		user-select: none;
		transition: all ease 0.25s;
		border-radius: 100px;
		z-index: 2;
		position: relative;

		&.is-active {
			background: none;
			color: var(--white);
		}
	}
}
