.case-studies-slider {
	background: var(--lightest-grey);
	border-top: 1px solid var(--light-grey);
	padding: 12rem 0;

	@include screen(md) {
		padding: 8rem 0;
	}

	&__inner {
		background: var(--white);
		padding: 7.5rem 12rem;

		@include screen(md) {
			padding: 4rem 7rem;
		}

		@include screen(sm) {
			padding: 3rem;
		}
	}

	&__header {
		text-align: center;
		padding-bottom: 9rem;

		@include screen(md) {
			padding-bottom: 4rem
		}

		& h2 {
			position: relative;
			display: inline-block;

			&::after {
				width: 7.5rem;
				content: '';
				position: absolute;
				left: -9.5rem;
				height: 0.1rem;
				background: var(--light-grey);
				top: calc(50% - 0.1rem);
			}

			&::before {
				content: '';
				width: 2rem;
				height: 2rem;
				background: var(--primary);
				display: block;
				position: absolute;
				left: -6.75rem;
				bottom: calc(50% - 1rem);
				z-index: 1;
			}
		}
	}

	&__item {
		display: flex !important;
		justify-content: space-between;
		color: inherit;
		font-weight: inherit;

		@include screen(md) {
			flex-wrap: wrap;
		}

		&:hover {
			text-decoration: none;
			color: inherit;

			.case-studies-slider__title {
				color: var(--primary);
			}
		}
	}

	&__photo,
	&__content {
		flex: 0 0 45%;

		@include screen(lg) {
			flex: 0 0 47%;
		}

		@include screen(md) {
			flex: 0 0 100%;
		}
	}

	&__photo {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		height: 28rem;

		@include screen(md) {
			height: auto;
			margin-bottom: 2rem;
		}
	}

	&__title {
		font-size: 2.8rem;
		margin-bottom: 0.5em;
		transition: all ease 0.25s;

		@include screen(lg) {
			font-size: 2.4rem;
		}
	}

	&__text {
		font-size: 2rem;
		margin: 0;

		@include screen(lg) {
			font-size: 1.7rem;
		}
	}
}
