.dropdown-submenu {
	display: flex;
	flex-wrap: wrap;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	padding: 2.5rem;

	&__item {
		color: var(--secondary);
		flex: 0 0 50%;
		padding-bottom: 3rem;

		@include screen(sm) {
			&:last-child {
				flex: 0 0 100%;
			}
		}
	}

	&__link {
		color: var(--secondary);
		display: block;
		font-weight: 300;
		position: relative;
		transition: all ease 0.25s;
		margin-left: 0;
		padding-left: 1rem;

		&::before {
			left: 0;
			position: absolute;
			top: 0;
			height: 0;
			width: 3px;
			background: var(--primary);
			content: '';
			transition: height ease 0.25s;
			will-change: transform, height;
		}

		&:hover {
			text-decoration: none;
			color: var(--secondary);
			transition: all ease 0.25s;

			&::before {
				transition: height ease 0.25s;
				height: 100%;
			}

			.dropdown-submenu__title,
			.dropdown-submenu__text {
				left: 5px;
			}
		}
	}

	&__title {
		margin: 0;
		padding: 0;
		font-weight: 600;
		font-size: 1.7rem;
		margin-bottom: 0.4em;
		position: relative;
		left: 0;
		transition: all ease 0.25s;

		@include screen(lg) {
			font-size: 1.5rem;
		}
	}

	&__text {
		position: relative;
		margin: 0;
		padding: 0;
		line-height: 1.4;
		width: 85%;
		left: 0;
		transition: all ease 0.25s;

		@include screen(lg) {
			font-size: 1.4rem;
			width: 90%;
		}

	}

	&__btn {
		margin-top: 0.75em;

		@include screen(sm) {
			width: 100%;
		}
	}
}
