.mobile-menu-container {
	background: rgba(0,0,0,0.2);
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 99;
	pointer-events: none;
	display: none;
}

.wrapper {
	left: 0;
	position: relative;
	transition: all ease 0.25s;
}


.mobile-menu-opened {
	.wrapper {
		left: 30rem;
	}
	.mobile-menu {
		left: 0;
	}
	.mobile-menu-container {
		pointer-events: all;
		display: block;
	}
	.header {
		left: 30rem;
	}
}

.mobile-menu {
	transition: all ease 0.25s;
	width: 30rem;
	background: var(--white);
	position: fixed;
	top: 0;
	left: -35rem;
	bottom: 0;
	z-index: 999;
	box-shadow: 0 10px 35px rgba(0,0,0,0.4);

	.navigation {
		flex-wrap: wrap;
		display: block;
		padding-top: 2rem;

		&-submenu {
			margin: 0;
			padding: 0;
			display: none;

			.navigation__link {
				padding-left: 6rem;
				font-size: 0.9em;
			}


		}

		&__item {
			display: block;
			font-size: 1.8rem;

			&--last {
				border-bottom: 1px solid #eee;
				padding-bottom: 1rem;
			}
		}

		&__link {
			padding: 1.5rem 4rem;
			width: 100%;

			&--sign-in::before {
			    content: '';
			    background-image: url(../img/icons/icon--user.png);
			    display: inline-block;
			    width: 2.1rem;
			    height: 2.1rem;
			    -moz-background-size: contain;
			    background-size: contain;
			    vertical-align: middle;
			    margin-right: 1rem;
			}

			&:hover {
				background: #eee;
			}

			&.is-dropdown::after {
				content: '\f105';
				display: block;
				position: absolute;
				right: 3rem;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				font-size: 1.5em;
				margin-left: 0.5em;
			}

			&.is-toggled::after {
				content: '\f107'
			}

			&.is-toggled + .navigation-submenu {
				display: block;
			}
		}

	}
}
