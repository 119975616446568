.page-header {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 75rem;
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 11rem 0;
	border-bottom: 0.1rem solid var(--light-grey);

	@include screen(lg) {
		min-height: 66rem;
	}

	@include screen(md) {
		min-height: 50rem;
		padding-top: 8rem;
		padding-bottom: 20rem;
	}

	@include screen(sm) {
		min-height: auto;
		height: auto;
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	&::after {
	    content: '';
	    width: 2rem;
	    height: 2rem;
	    background: var(--primary);
	    display: block;
	    position: absolute;
	    left: calc(50% - 1rem);
	    bottom: -1rem;
	}

	&__graphic {
		z-index: 2;
		width: 100rem;
		pointer-events: none;
		position: absolute;
		top: 8rem;
		right: -10rem;

		@include screen(lg) {
		    width: 85rem;
		    top: 10rem;
		    right: -10rem;
		}

		@include screen(md) {
		    width: 75rem;
		    top: 10rem;
		    right: -29rem;
		}

		@include screen(sm) {
			width: 100%;
			top: inherit;
			right: inherit;
			position: relative;
			z-index: 1;
		}
	}

	.card-block {
		max-width: 58rem;
		margin: 0;

		@include screen(sm) {
			max-width: none;
			width: 90%;
			margin: 0 auto;
			z-index: 2;
			position: relative;
			margin-bottom: 4rem;
		}

		.headline {
			max-width: 80%;
		}

		.btn {
			margin-top: 2rem;

			@include screen(sm) {
				width: 100%;
			}
		}
	}
}
