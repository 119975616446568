.card-block {
	box-shadow: 0px 4px 22px 0px rgba(20, 20, 20, 0.05);
	background: var(--white);
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;

	&__header {
		margin: 0;
		padding: 0;
		background: var(--lightest-grey);
		text-transform: uppercase;
		font-weight: 300;
		padding: 2rem 4rem;
		flex: 0 0 100%;
	}

	&__body {
		padding: 3rem 4rem;
	}

	&--wide {
		justify-content: space-between;
		align-items: center;
		padding: 5rem 0;

		@include screen(sm) {
			padding-top: 2rem;
		}

		.card-block__body {
			flex: 0 0 40%;
			position: relative;
			padding-left: 11rem;

			@include screen(md) {
				flex: 0 0 100%;
				order: 2;
			}

			@include screen(sm) {
				padding: 3rem 4rem;
				padding-bottom: 0;
				order: 2;
			}

			&::before {
				content: '';
				width: 2rem;
				height: 2rem;
				background: var(--primary);
				display: block;
				position: absolute;
				z-index: 1;
				left: 4.5rem;
				top: calc(50% - 1rem);

				@include screen(sm) {
					display: none;
				}
			}

			&::after {
			    width: 0.1rem;
			    content: '';
			    position: absolute;
			    height: 8rem;
				left: 5.5rem;
			    background: var(--light-grey);
				top: calc(50% - 4rem);

				@include screen(sm) {
					display: none;
				}
			}
		}

		.card-block__graphic {
			flex: 0 0 50%;
			margin-right: 5rem;

			@include screen(md) {
				flex: 0 0 100%;
				margin: 0;
				padding: 0 4rem;
				order: 1;
			}


			img {
			}
		}
	}
}
