.service-columns {
	display: flex;
	margin: 0 -6rem;

	&__item {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
		padding: 0 6rem;
		display: flex;
		flex-wrap: wrap;
		position: relative;

		&:nth-of-type(2) {
			border-left: 1px solid var(--light-grey);
			border-right: 1px solid var(--light-grey);

			@include screen(sm) {
				border: none;
			}

			&::before {
				content: '';
				width: 2rem;
				height: 2rem;
				background: var(--primary);
				display: block;
				position: absolute;
				bottom: -1rem;
				left: -1rem;

				@include screen(sm) {
					display: none;
				}
			}

			&::after {
				content: '';
				width: 2rem;
				height: 2rem;
				background: var(--primary);
				display: block;
				position: absolute;
				bottom: -1rem;
				right: -1rem;
				@include screen(sm) {
					display: none;
				}
			}
		}
	}

	&__title {
		text-transform: uppercase;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
		position: relative;

		&::after {
			content: '';
			width: 7.5rem;
			background: var(--secondary);
			height: 1px;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}

	&__link {
		@include hover-arrow;
	}

	&__graphic {
		align-self: flex-end;
		margin-bottom: 5rem;
	}
}
