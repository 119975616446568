// arrows on hover
@mixin hover-arrow {
	&:hover {
		text-decoration: none;

		&::after {
			opacity: 1;
			left: 0.75rem;
		}
	}

	&::after {
		transition: all ease 0.25s;
		content: '\f061';
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		left: 0rem;
		font-size: 0.95em;
		position: relative;
		opacity: 0;
	}
}

// breakpoints responsive
//
//

@mixin screen($size) {
	@if $size == xs {
		@media (max-width: 499px) {
			@content;
		}
	} @else if $size == sm {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $size == md {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $size == lg {
		@media (max-width: 1199px) {
			@content;
		}
	}
}
