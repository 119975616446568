.logo-slider {
	background: var(--white);
	display: flex;
	padding: 0rem 5rem !important;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0px 4px 22px 0px rgba(20, 20, 20, 0.05);
	border-radius: var(--border-radius);
	margin: 4rem 0;


	.slick-slide {
		display: flex;
		height: inherit !important;
		align-items: center;
		text-align: center;
		justify-content: center;
		padding: 5rem;

		@include screen(sm) {
			padding: 2rem;
		}

	}

	.slick-track {
		display: flex !important;
	}

	.slick-dotted.slick-slider {
		padding-bottom: 0;
	}

	.slick-dots {
		left: 0;
		bottom: -5rem;
	}

	.slick-next, .slick-prev {
		width: 5rem;
		height: 5rem;
	}

	.slick-prev {
		left: 1rem;
	}

	.slick-next {
		right: 1rem;
	}

	.slick-next:before {
		content: '';
		background-image: url('../img/icons/icon--angle-right.png');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		width: 5rem;
		height: 5rem;
		display: block;
		opacity: 1;
	}

	.slick-prev:before {
		content: '';
		background-image: url('../img/icons/icon--angle-left.png');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		width: 5rem;
		height: 5rem;
		display: block;
		opacity: 1;
	}

}
