.photo-card {
	cursor: pointer;
	display: block;
	font-weight: inherit;
	text-decoration: none;
	color: inherit;
	display: flex;


	&__body {
		background: var(--white);
		box-shadow: 0px 4px 22px 0px rgba(20, 20, 20, 0.1);
		border-radius: var(--border-radius);
		    width: 100%;
	}

	&:hover {
		font-weight: inherit;
		text-decoration: none;
		color: inherit;

		.photo-card__image {
			background-size: 105%;
		}

		.photo-card__read-more::after {
			opacity: 1;
			left: 0.75rem;
		}
	}

	&__image {
		padding: 30% 0;
		display: block;
		background: url("../img/pages/home/slider-one.png");
		background-size: 100%;
		background-position: center center;
		transition: all ease 0.25s;
		image-rendering: auto;
	}

	&__inner {
		padding: 3rem;
		display: flex;
		flex-wrap: wrap;
	}

	&__title {
		margin: 0;
		margin-bottom: 1em;
		padding: 0;
		font-size: 2rem;
		font-weight: 400;
		display: block;
		flex: 0 0 100%;
	}

	&__tag {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 2rem;

		span {
			flex: 0 0 100%;
			line-height: 1.2;

			&:first-child {
				font-weight: 600;
				text-transform: uppercase;
			}
		}
	}

	&__read-more {
		color: var(--primary);
		font-weight: 600;

		&::after {
			transition: all ease 0.25s;
			content: '\f061';
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			left: 0rem;
			font-size: 0.95em;
			position: relative;
			opacity: 0;
		}
	}

	&__label {
		color: var(--light-grey);
	}



	&__excerpt {
		margin-bottom: 1.4em;
		line-height: 1.3;
		word-break: break-word;
	}

}
