//bugs with contact form 7

.form__textarea {
	height: 13rem;
}


// temporarily hide
//
// .header {
// 	display: none;
// }
