html {
	font-size: 62.5%;
	image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
	overflow-x: hidden;
	scroll-behavior: smooth;
}

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 1.7rem;
	font-weight: 300;
	color: var(--secondary);
	overflow-x: hidden;
	padding-top: 76px;


	@include screen(md) {
		font-size: 1.4rem;
	}
}


a:active,
a:focus,
input:focus {
	outline: none;
}

:focus {
	outline: none;
}

a {
	font-weight: 600;
	color: var(--primary);

	&:hover {
		color: var(--primary);
	}
}

a.anchor-arrow {
	@include hover-arrow();
}
